<template>
  <div class="share-popup">
    <div class="modal fade" :id="modalId" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title" style="font-weight: 600;">مشاركة {{ isBook ? 'الكتاب' : 'المجلة' }}</p>
            <button type="button" class="close" @click="hideModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="share-icons">
              <div v-for="network in networks" :key="network.name" :class="`share-${network.name}`">
                <ShareNetwork v-if="network.name != 'link'" :network="network.name" :url="shareContent.url || ''"
                  :title="shareContent.title" :description="shareContent.description" :hashtags="shareContent.hashtags">
                  <i :class="network.icon"></i>
                </ShareNetwork>
                <div v-else @click="onCopyLink">
                  <i :class="network.icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBook: {
      type: Boolean,
      default: () => false,
    },
    entity: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      networks: [
        {
          name: 'facebook',
          icon: 'fa fa-facebook-square',
        },
        {
          name: 'whatsapp',
          icon: 'fa fa-whatsapp',
        },
        {
          name: 'telegram',
          icon: 'fa fa-telegram',
        },
        {
          name: 'link',
          icon: 'fa fa-link',
        }
      ]
    }
  },
  computed: {
    bookUrl() {
      const url = new URL(window.location.href);
      return `${url.origin}/#/zadi/books/${this.entity?.id}`;
    },
    modalId() {
      return this.isBook ? `share-popup-book` : `share-popup-magazine`;
    },
    slicedDescription() {
      if (!this.isBook) return this.description
      return this.entity?.description.length > 300 ? `${this.entity?.description.slice(0, 300)}..الخ` : this.entity?.description.slice(0, 300);
    },
    shareContent() {
      return {
        url: this.isBook ? this.bookUrl : this.entity?.link,
        title: this.isBook ? `ملخص ${this.entity?.title}` : `مجلة زادي العدد ${this.entity?.releaseNumber}`,
        description: this.isBook ? this.slicedDescription : "مجلة زادي مجلة ثقافية أسبوعية.. لنتزود قراءة ثم فكراً فحضارة",
        hashtags: "مجلة زادي ,ملخصات كتب ,مؤسسة التميز التنموية"
      }
    }
  },
  methods: {
    onCopyLink() {
      const link = this.isBook ? this.bookUrl : this.entity?.link
      navigator.clipboard.writeText(link);
      this.hideModal()
    },
    hideModal() {
      $(`#${this.modalId}`).modal('hide');
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

/* .modal-dialog {
  max-width: 350px;
} */

.share-popup {
  font-family: "Tajawal", sans-serif;
}

.share-icons {
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px
}

.share-icons i {
  font-size: 45px;
  transition: all 0.2s linear;
}

.share-icons i:hover {
  transform: scale(1.05);
}

.share-icons .share-facebook i {
  color: #3b5998;
}

.share-icons .share-whatsapp i {
  color: #25d366;
}

.share-icons .share-telegram i {
  color: #0088cc;
  font-size: 40px;
}

.share-icons .share-link {
  cursor: pointer;
}

.share-icons .share-link i {
  color: #434a4e;
  font-size: 40px;
}
</style>
