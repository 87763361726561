<template>
  <div class="recent-magazines-section">
    <home-section-header title="صدر حديثاً" route-name="ZadiMagazines" />
    <magazine-card-wrapper :recent-magazines="recentMagazines" :is-loading-magazines="isLoadingRecentMagazines" />
  </div>
</template>

<script>
import MagazineCardWrapper from "../../../website-components/zadi/magazines/MagazineCardWrapper.vue";
import HomeSectionHeader from "../../../website-components/zadi/shared/HomeSectionHeader.vue";
import zadiRepository from "../../../repositories/zadiRepository";

export default {
  components: { MagazineCardWrapper, HomeSectionHeader },
  data() {
    return {
      recentMagazines: null,
      isLoadingRecentMagazines: false,
    }
  },
  async mounted() {
    this.isLoadingRecentMagazines = true;
    const res = await zadiRepository.getRecentMagazines();
    this.recentMagazines = await res?.data?.data;
    this.isLoadingRecentMagazines = false;
  }
};
</script>

<style scoped>
.recent-magazines-section {
  /* padding: 120px 40px 0px 40px; */
  padding: 120px 0px 0px 0px;
  background:
    url("../../../../../../public/assets/images/website/zadi/bg-book.png") center top -20px no-repeat;
  background-size: 300px;
}

@media (max-width: 560px) {
  .recent-magazines-section {
    padding: 60px 0px 0px 0px;
    background: unset;
  }
}
</style>