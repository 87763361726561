<template>
  <div class="magazine-card">
    <div class="content" @click="onOpenMagazine()">
      <div class="image">
        <img src="../../../../../../public/assets/images/website/zadi/magazine-cover.jpg" />
      </div>
      <div class="text">
        <span>مجلة زادي | العدد {{ magazine?.releaseNumber }}</span>
      </div>
    </div>
    <div class="actions">
      <button @click="onShareMagazine">
        <img src="../../../../../../public/assets/images/website/zadi/share.png" />
        مشاركة العدد
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    magazine: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    onOpenMagazine() {
      const link = this.magazine.link;
      window.open(link, '_blank').focus();
    },
    onShareMagazine() {
      this.$emit('share-magazine', this.magazine)
    },
  },
};
</script>

<style scoped>
.magazine-card {
  width: 100%;
  min-height: 280px;
  max-height: 320px;
  /* max-width: 280px; */
  /* min-height: 280px; */
  border-radius: 25px;
  border: 2px solid #dfdfdf;
  padding: 8px;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.magazine-card .content {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;
}

.magazine-card .content .image::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
      rgba(157, 204, 195, 0.1) 0%,
      rgba(71, 206, 198, 0.9) 100%);
}

.magazine-card .content .text {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  color: white;
  font-weight: 500;
  font-size: 20px;
  padding: 0px 10px 15px 10px;
}

.magazine-card .content {
  transition: all 0.2s ease-in-out;
  height: 250px;
}

.magazine-card .content:hover .image img {
  transform: scale(1.05);
}

.magazine-card .content .image {
  width: 100%;
  height: 100%;
}

.magazine-card .content .image img {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  object-fit: cover;
}

.magazine-card .actions {
  margin-top: 10px;
}

.magazine-card .actions button {
  background: #f4acbf;
  border-radius: 20px;
  color: white;
  padding: 6px;
  width: 100%;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}

.magazine-card .actions button img {
  width: 25px;
}

.magazine-card .actions button:hover {
  background: #f58da9;
}
</style>