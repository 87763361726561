var render = function () {
  var _vm$recentBook, _vm$recentBook2, _vm$recentBook3, _vm$recentBook4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "book-summary"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": (_vm$recentBook = _vm.recentBook) === null || _vm$recentBook === void 0 ? void 0 : _vm$recentBook.imageUrl
    }
  })]), _c('div', {
    staticClass: "info"
  }, [_c('p', [_vm._v(_vm._s((_vm$recentBook2 = _vm.recentBook) === null || _vm$recentBook2 === void 0 ? void 0 : _vm$recentBook2.title))]), _c('p', [_vm._v(_vm._s((_vm$recentBook3 = _vm.recentBook) === null || _vm$recentBook3 === void 0 ? void 0 : _vm$recentBook3.author))])])]), _c('div', {
    staticClass: "col-lg-9"
  }, [_c('div', {
    staticClass: "details"
  }, [_c('p', [_vm._v(_vm._s((_vm$recentBook4 = _vm.recentBook) === null || _vm$recentBook4 === void 0 ? void 0 : _vm$recentBook4.description))])]), _c('div', {
    staticClass: "actions"
  }, [_c('button', {
    on: {
      "click": _vm.onShareRecentBook
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../../public/assets/images/website/zadi/share.png")
    }
  }), _vm._v(" مشاركة الملخص ")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }