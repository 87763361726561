var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-section"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../../public/assets/images/website/zadi/hero/hero-main.png")
    }
  })]);
}]

export { render, staticRenderFns }