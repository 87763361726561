var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "share-popup"
  }, [_c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": _vm.modalId,
      "tabindex": "-1",
      "data-backdrop": "static"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered modal-sm"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('p', {
    staticClass: "modal-title",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("مشاركة " + _vm._s(_vm.isBook ? 'الكتاب' : 'المجلة'))]), _c('button', {
    staticClass: "close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "share-icons"
  }, _vm._l(_vm.networks, function (network) {
    return _c('div', {
      key: network.name,
      class: ("share-" + (network.name))
    }, [network.name != 'link' ? _c('ShareNetwork', {
      attrs: {
        "network": network.name,
        "url": _vm.shareContent.url || '',
        "title": _vm.shareContent.title,
        "description": _vm.shareContent.description,
        "hashtags": _vm.shareContent.hashtags
      }
    }, [_c('i', {
      class: network.icon
    })]) : _c('div', {
      on: {
        "click": _vm.onCopyLink
      }
    }, [_c('i', {
      class: network.icon
    })])], 1);
  }), 0)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }