<template>
  <div class="zadi-home-page">
    <hero-section />
    <div class="wrapper">
      <recent-magazines-section />
      <recent-book-section />
      <!-- <recent-book-video-section /> -->
    </div>
  </div>
</template>

<script>
import HeroSection from "../../website-components/zadi/home/HeroSection.vue";
import RecentMagazinesSection from "../../website-components/zadi/home/RecentMagazinesSection.vue";
import RecentBookSection from "../../website-components/zadi/home/RecentBookSection.vue";

export default {
  metaInfo: {
    title: "مشروع زادي | الرئيسية ",
  },
  components: { HeroSection, RecentMagazinesSection, RecentBookSection },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

.zadi-home-page {
  padding: 100px 0px 80px 0px;
  font-family: "Tajawal", sans-serif;
}

.wrapper {
  padding: 0px 40px 0px 40px;
}

@media (max-width: 560px) {
  .wrapper {
    padding: 0px 20px 0px 20px;
  }
}
</style>
